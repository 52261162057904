<template>
    <Fragment>
        <DataTableComponent
            :items="solicitudes.data"
            :show_loading="solicitudes.isLoading"
            :headers="headers"
            :total_registros="totalSolicitudes"
            class="mt-4"
            v-models:pagina="paginacion.page"
            v-models:select="paginacion.per_page"
            @paginar="manejarpaginacion"
        >
            <template v-slot:item.created_at="{ item }">
                {{  formatDate(item.created_at) }}
            </template>
            <template v-slot:item.institucion="{ item }">
                {{ item.solicitante.institucion?.nombre ?? '-' }}
            </template>
            <template v-slot:item.usuario_solicitante="{ item }">
                {{ item.solicitante.nombre_completo }}
                <b>({{ item.solicitante.email }})</b>
            </template>
            <template v-slot:item.perfil_usuario_solicitante="{ item }">
                {{ item.solicitante.perfiles?.map((perfil) => perfil.nombre).join(', ') ?? '-' }}
            </template>
            <template v-slot:item.oficial_cumplimiento="{ item }">
                {{ item.oficial?.nombre_completo ?? '-' }}
            </template>
            <template v-slot:item.es_solicitud_de_suspension="{ item }">
                <v-chip label>
                    <v-icon class="mr-1" style="font-size: 20px !important; transform: translateY(-1px);">
                        {{ item.tipo.icono }}
                    </v-icon>
                    {{ item.tipo.etiqueta }}
                </v-chip>
            </template>
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex justify-center align-center" style="gap: 4px;">
                    <!-- Visualizacion de solicitud de titular -->
                    <v-tooltip v-if="[3, 4].includes(item.tipo.id)" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on" 
                                v-bind="attrs" 
                                icon
                                @click.stop="abrirDetallesSolicitud(item)"
                            >
                                <v-icon color="secondary">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver detalle de solicitud</span>
                    </v-tooltip>
                    <!-- Aprobar solicitud -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on" 
                                v-bind="attrs" 
                                @click.stop="confirmarSuspensionOficial(item)"
                                icon 
                            >
                                <v-icon color="secondary">mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Aprobar solicitud</span>
                    </v-tooltip>
                    
                    <!-- Rechazar solicitud -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-on="on"
                                v-bind="attrs"
                                icon 
                                @click.stop="confirmarRechazoSolicitud(item)"
                            >
                                <v-icon color="secondary">mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Rechazar solicitud</span>
                    </v-tooltip>

                    <!-- Respuesta por correo electrónico -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on" 
                                v-bind="attrs"
                                icon
                                @click.stop="abrirModalEnvioCorreoElectronico(item)"
                            >
                                <v-icon color="secondary">mdi-email-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Enviar correo eletrónico</span>
                    </v-tooltip>

                    <!-- Justificacion suspension -->
                    <v-tooltip v-if="![3, 4].includes(item.tipo.id)" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on" 
                                v-bind="attrs"
                                @click.stop="visualizarJustificacionSuspension(item)"
                                icon 
                            >
                                <v-icon color="secondary">mdi-file-eye-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver motivo de {{ solicitudActiva?.tipo.id === 1 ? 'suspensión' : 'habilitación' }}</span>
                    </v-tooltip>
                </div>
            </template>
        </DataTableComponent>
        <ConfirmationModalComponent 
            :is-open="modalConfirmacionSuspension"
            :description="textoConfirmacion"
            :is-loading="suspensionOficial.isLoading"
            @cancel="modalConfirmacionSuspension = false"
            @confirm="suspenderOficialCumplimiento"
        />
        <ConfirmationModalComponent 
            :is-open="modalConfirmacionRechazoSuspension"
            description="¿Desea rechazar la solicitud?"
            :is-loading="rechazoSolicitud.isLoading"
            @cancel="modalConfirmacionRechazoSuspension = false"
            @confirm="rechazarSolicitud"
        />
        <PdfModal
            :is-open="modalVerJustificacionSuspensionAbierta"
            :filename="`justificacion-suspension(${solicitudActiva?.oficial?.nombre_completo})`"
            :source-loadable="visualizacionJustificacion"
            @on-visibility-change="cerrarVisualizacionJustificacionSuspension"
        />
        <EnviarCorreoElectronico 
            :isOpen="modalEnvioCorreoElectronicoAbierta"
            @on-visibility-change="cerrarModalEnviarCorreoElectronico"
            @on-save="confirmarEnvioCorreoElectronico"
        />
        <ConfirmationModalComponent 
            :is-open="confirmacionEnvioCorreoElectronicoAbierta"
            description="¿Desea continuar con el envío de correo eletrónico?"
            :is-loading="envioCorreoElectronico.isLoading"
            @cancel="confirmacionEnvioCorreoElectronicoAbierta = false"
            @confirm="enviarCorreoElectronico"
        />
        <ModalVerDetalleSolicitud 
            v-if="solicitudActiva?.tipo.id === 3"
            :is-open.sync="modalDetalleSolicitudUsuarioAbierta"
            :id-solicitud-usuario="solicitudActiva?.id_solicitud_creacion_usuario"
        />
        <RechazarGestionUsuario 
            :is-open.sync="modalRechazoGestionUsuarioAbierta"
            @on-save="confirmarRechazoGestionUsuario"
        />
        <ModalDetalleCambioPerfil
            :is-open.sync="modalDetalleCambioPerfilAbierta" 
            :id-solicitud="solicitudActiva?.id"
        />
    </Fragment>
</template>
<script>
import { createPageable, togglePageable, setPageableResponse, createLoadable, toggleLoadable, setLoadableResponse, isResponseSuccesful } from '@/utils/loadable';
import ModalVerDetalleSolicitud from './solicitudes/ModalVerDetalleSolicitud.vue';
import ModalDetalleCambioPerfil from './solicitudes/ModalDetalleCambioPerfil.vue';
import EnviarCorreoElectronico from './solicitudes/EnviarCorreoElectronico.vue';
import RechazarGestionUsuario from './solicitudes/RechazarGestionUsuario.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import { convertToFormData } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';
import { formatDate } from '@/utils/datetime';
import { Fragment } from 'vue-fragment';

export default {
    name: 'SolicitudesSuspension',
    components: { 
        DataTableComponent, 
        ConfirmationModalComponent, 
        Fragment, 
        PdfModal, 
        EnviarCorreoElectronico,
        ModalVerDetalleSolicitud,
        RechazarGestionUsuario,
        ModalDetalleCambioPerfil,
    },
    data: () => ({
        headers: [
            { align: 'center', sortable: false, text: 'Fecha creación', value: 'created_at' },
            { align: 'center', sortable: false, text: 'Institución', value: 'institucion' },
            { align: 'center', sortable: false, text: 'Usuario solicitante', value: 'usuario_solicitante' },
            { align: 'center', sortable: false, text: 'Perfil usuario solicitante', value: 'perfil_usuario_solicitante' },
            { align: 'center', sortable: false, text: 'Oficial de cumplimiento', value: 'oficial_cumplimiento' },
            { align: 'center', sortable: false, text: 'Tipo de solicitud', value: 'es_solicitud_de_suspension' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
        solicitudes: createPageable([]),
        paginacion: {
            page: 1,
            per_page: 10,
        },
        // Acciones
        solicitudActiva: null,
        cargaUtilAprobaciones: null,
        // Rechazo solicitud
        modalConfirmacionRechazoSuspension: null,
        rechazoSolicitud: createLoadable(null),
        // Aprobacion solicitud
        modalConfirmacionSuspension: null,
        suspensionOficial: createLoadable(null),
        // Visualizar motivo
        modalVerJustificacionSuspensionAbierta: false,
        visualizacionJustificacion: createLoadable(null),
        // Envio Correo
        confirmacionEnvioCorreoElectronicoAbierta: false,
        modalEnvioCorreoElectronicoAbierta: false,
        envioCorreoElectronico: createLoadable(null),
        estructuraEmail: null,
        // Detalle solicitud de usuario
        modalDetalleSolicitudUsuarioAbierta: false,
        // Rechazo solicitud gestion usuario
        modalRechazoGestionUsuarioAbierta: false,
        // Detalle de cambio de perfil
        modalDetalleCambioPerfilAbierta: false,
    }),
    computed: {
        totalSolicitudes() {
            return this.solicitudes.pagination.total_rows;
        },
        ejecutandoAccionSobreSolicitud() {
            return this.suspensionOficial.isLoading || this.rechazoSolicitud.isLoading;
        },
        textoConfirmacion() {
            if (!this.solicitudActiva) return '';

            if (this.solicitudActiva.tipo.id === 3) return '¿Desea aprobar la solicitud de creación de usuario de cumplimiento?';

            if (this.solicitudActiva.tipo.id === 4) return '¿Desea aprobar la solicitud de cambio de perfil?';


           return  `¿Desea ${this.solicitudActiva?.tipo.id === 1 ? 'suspender' : 'habilitar'} al oficial de cumplimiento?`
        },
    },
    methods: {
        // UI
        formatDate,
        manejarpaginacion(paginacionActualizada) {
            const { pagina, cantidad_por_pagina } = paginacionActualizada;
            this.paginacion = { page: pagina, per_page: cantidad_por_pagina };
        },
        confirmarSuspensionOficial(solicitud) {
        this.solicitudActiva = { ...solicitud };
            this.modalConfirmacionSuspension = true;
        },
        confirmarRechazoSolicitud(solicitud) {
            this.solicitudActiva = { ...solicitud };
            
            if ([3, 4].includes(solicitud.tipo.id)) {
                this.modalRechazoGestionUsuarioAbierta = true;
            } else {
                this.modalConfirmacionRechazoSuspension = true;
            }
        },
        cerrarVisualizacionJustificacionSuspension(visible) {
            this.modalVerJustificacionSuspensionAbierta = visible;
        },
        confirmarRechazoGestionUsuario(motivo) {
            this.cargaUtilAprobaciones = { ...motivo };
            this.modalConfirmacionRechazoSuspension = true;
        },
        verDetallesSolicitudCreacion(solicitud) {
            this.solicitudActiva = { ...solicitud };
            this.modalDetalleSolicitudUsuarioAbierta = true;
        },
        abrirModalDetalleCambioPerfil(solicitud) {
            this.solicitudActiva = { ...solicitud };
            this.modalDetalleCambioPerfilAbierta = true;
        },
        abrirDetallesSolicitud(solicitud) {
            switch (solicitud.tipo.id) {
                case 3:
                    this.verDetallesSolicitudCreacion(solicitud);
                    break;
                case 4:
                    this.abrirModalDetalleCambioPerfil(solicitud);
                    break;
            }
        },
        // Data
        async cargarSolicitudes() {
            const filtros = {
                pagination: true,
                ...this.paginacion,
            };

            togglePageable(this.solicitudes);
            const { data, headers } = await this.services.VerificacionDocumentosOdc.listarSolicitudesSuspension(filtros);
            setPageableResponse(this.solicitudes, data, headers);
        },
        async suspenderOficialCumplimiento() {
            toggleLoadable(this.suspensionOficial);
            const { data } = await this.services.VerificacionDocumentosOdc.aprobarSolicitudSuspension(this.solicitudActiva.id);
            this.modalConfirmacionSuspension = false;
            setLoadableResponse(this.suspensionOficial, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarSolicitudes(); 
        },
        async rechazarSolicitud() {
            toggleLoadable(this.rechazoSolicitud);
            const { data } = await this.services.VerificacionDocumentosOdc.rechazarSolicitudSuspension(this.solicitudActiva.id, this.cargaUtilAprobaciones);
            this.modalConfirmacionRechazoSuspension = false;
            setLoadableResponse(this.rechazoSolicitud, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarSolicitudes(); 
        },
        async visualizarJustificacionSuspension(solicitud) {
            this.solicitudActiva = solicitud;
            this.modalVerJustificacionSuspensionAbierta = true;
            toggleLoadable(this.visualizacionJustificacion);
            const { data } = await this.services.VerificacionDocumentosOdc.visualizarJustificacionSuspension(solicitud.oficial.id);
            setLoadableResponse(this.visualizacionJustificacion, data, { isFile: true });
        },
        abrirModalEnvioCorreoElectronico(solicitud) {
            this.solicitudActiva = { ...solicitud };
            this.modalEnvioCorreoElectronicoAbierta = true;
        },
        cerrarModalEnviarCorreoElectronico(visible) {
            this.modalEnvioCorreoElectronicoAbierta = visible;
        },
        confirmarEnvioCorreoElectronico(email) {
            this.estructuraEmail = email;
            this.modalEnvioCorreoElectronicoAbierta = false;
            this.confirmacionEnvioCorreoElectronicoAbierta = true;
        },
        async enviarCorreoElectronico() {
            const formData = convertToFormData(this.estructuraEmail);
            toggleLoadable(this.envioCorreoElectronico);
            const { data } = await this.services.VerificacionDocumentosOdc.enviarCorreoCreadorSolicitud(this.solicitudActiva.id, formData);
            this.confirmacionEnvioCorreoElectronicoAbierta = false;
            setLoadableResponse(this.envioCorreoElectronico, data, { showAlertOnSuccess: true, skipOnSuccess: true });
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarSolicitudes();
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>