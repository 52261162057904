<template>
    <Fragment>
        <DataTableComponent 
            :show_loading="gestiones.isLoading"
            :headers="headers"
            :items="gestiones.data"
            :total_registros="totalRegistros"
            @paginar="manejarPaginacion"
        >
            <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDate(item.created_at) }}
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
                {{ formatDate(item.updated_at) }}
            </template>
            <template v-slot:[`item.titulo`]="{ item }">
                <span class="d-inline-block" style="min-width: 125px;">
                    {{ item.titulo }}
                </span>
            </template>
            <template v-slot:[`item.estado`]="{ item }">
                <v-chip label>
                    {{ item.estado.nombre }}
                </v-chip>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
                <div style="min-width: 125px;">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.stop="visualizarAdjuntoGestionCanalComunicacion(item)" 
                            v-bind="attrs" 
                            v-on="on" 
                            color="primary"
                            :disabled="(gestionActiva?.id !== item.id && visualizacionAdjuntoCanalComunicacion.isLoading) || !Boolean(item.adjunto)"
                            :loading="gestionActiva?.id === item.id && visualizacionAdjuntoCanalComunicacion.isLoading"
                            icon
                            >
                                <v-icon>mdi-file-pdf-box</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver documento de gestión</span>
                    </v-tooltip>
                </div>
            </template>
        </DataTableComponent>
         <!-- Modificacion de adjuntos -->
        <AdjuntoGestion 
            :is-open="modalEdicionAdjuntoGestion"
            @on-visibility-change="manejarVisibilidadEditAdjunto"
            @on-save="confirmarEdicionAdjunto"
        />
        <ConfirmationModalComponent 
            :is-open="confirmacionEdicionAdjuntoGestion"
            description="¿Desea actualizar el adjunto?"
            :is-loading="edicionAdjunto.isLoading"
            @confirm="editarAdjunto"
            @cancel="confirmacionEdicionAdjuntoGestion = false"
        />
        <PdfModal
            :isOpen="modalVisualizacionAdjuntoCanalComunicacion" 
            :source-loadable="visualizacionAdjuntoCanalComunicacion" 
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjuntoCanalComunicacion"
            filename="adjunto-gestion"
        />
    </Fragment>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { Fragment } from "vue-fragment";
import { formatDate } from '@/utils/datetime';
import { ConfirmationModalComponent } from "@/components/utils";
import { isResponseSuccesful, toggleLoadable, createLoadable, setLoadableResponse } from "@/utils/loadable";
import PdfModal from '@/components/PdfModal.vue';
import AdjuntoGestion from "./AdjuntoGestion.vue";


export default {
    name: 'ListaDeGestiones',
    components: { DataTableComponent, Fragment, ConfirmationModalComponent,PdfModal,AdjuntoGestion },
    emits: ['on-paginar', 'on-actualizacion'],
    props: {
        gestiones: { type: Object, required: true },
    },
    data: () => ({
        modalEdicionAdjuntoGestion: false,
        headers: [
            { sortable: false, align: 'center', text: 'Código', value: 'codigo' },
            { sortable: false, align: 'center', text: 'Titulo', value: 'titulo' },
            { sortable: false, align: 'center', text: 'Tipo', value: 'tipo.nombre' },
            { sortable: false, align: 'center', text: 'Fecha de creación', value: 'created_at' },
            { sortable: false, align: 'center', text: 'Acciones', value: 'acciones' },
        ],
        gestionSeleccionada: null,
        // Visualizacion
        modalVisualizacionAdjuntoCanalComunicacion: false,
        visualizacionAdjuntoCanalComunicacion: createLoadable(null),
        gestionActiva: null,
        // Edicion adjunto        
        confirmacionEdicionAdjuntoGestion: false,
        edicionAdjuntoInfo: null,
        edicionAdjunto: createLoadable(null),

    }),
    computed: {
        esAdministradorDeGestiones() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
        totalRegistros() {
            return Number(this.gestiones.pagination.total_rows);
        },
    },
    methods: {
        formatDate,
        manejarVisibilidadEditAdjunto(visible) {
            this.modalEdicionAdjuntoGestion = visible;
        },
        // Edicion gestion
        abrirModalEdicionGestion(gestion) {
            this.gestionSeleccionada = gestion ;
            this.modalEdicionAdjuntoGestion = true;
        },
        confirmarEdicionAdjunto(adjunto) {
            this.edicionAdjuntoInfo = adjunto;
            this.modalEdicionAdjuntoGestion = false;
            this.confirmacionEdicionAdjuntoGestion = true;
        },
        async editarAdjunto() {
            const idGestion = this.gestionSeleccionada.id;
            const formData = new FormData();
            formData.append('adjunto', this.edicionAdjuntoInfo);

            toggleLoadable(this.edicionAdjunto)
            const { data } = await this.services.canalesDeComunicacion.editarAdjuntoGestion(idGestion, formData);
            setLoadableResponse(this.edicionAdjunto, data, { skipOnSuccess: true, showAlertOnSuccess: true });
            this.confirmacionEdicionAdjuntoGestion = false;

            if (!isResponseSuccesful(data)) return;
            this.$emit('on-actualizacion');
        },
        // Visualizacion adjunto
        manejarVisibilidadModalVisualizacionAdjuntoCanalComunicacion(visible) {
            this.modalVisualizacionAdjuntoCanalComunicacion = visible;
        },
        manejarPaginacion(paginacion) {
            const { cantidad_por_pagina, pagina } = paginacion;
            this.$emit('on-paginar', { page: pagina, per_page: cantidad_por_pagina });
        },
        async visualizarAdjuntoGestionCanalComunicacion(gestion){
            this.gestionActiva = gestion;
            this.modalVisualizacionAdjuntoCanalComunicacion = true;

            toggleLoadable(this.visualizacionAdjuntoCanalComunicacion);
            const { data } = await this.services.canalesDeComunicacion.visualizarAdjuntoCanalComunicacion(gestion.id);
            setLoadableResponse(this.visualizacionAdjuntoCanalComunicacion, data, { isFile: true });
        },
    },
}
</script>