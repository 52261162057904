<template>
    <Fragment>
        <v-row dense class="mx-auto mt-4" align="center" justify="start" style="max-width: 1200px;">
            <v-col cols="12">
                <div class="d-flex flex-column-reverse flex-sm-row align-center flex-wrap mb-2 filtros-header" style="gap: 16px;">
                    <h5 class="text-h6 font-weight-bold text-uppercase mb-0">Filtros</h5>
                    <InstitucionesSinOficial />
                    <v-btn
                        color="secondary"
                        class="d-block px-4 py-2"
                        style="height: unset !important; min-height: 36px !important;"
                        outlined
                        @click.stop="generarReporte"
                        :loading="generacionReporte.isLoading"
                    >
                        <v-icon class="mr-2" color="secondary">mdi-file-excel</v-icon>
                        Reporte
                    </v-btn>
                </div>
                <v-divider class="mb-4" />
            </v-col>

            <v-col cols="12" sm="6">
                <v-text-field
                    v-model="filtros.nombre"
                    :disabled="oficialesCumplimiento.isLoading"
                    label="Nombre"
                    placeholder="Buscar por nombre"
                    class="flex-grow-1 flex-shrink-1"
                    outlined
                    hide-details
                />
            </v-col>
            <v-col cols="12" sm="6">
                <ProgresiveLoadAutocomplete
                    v-model="filtros.id_institucion"
                    :pageable="instituciones"
                    :loading="instituciones.isLoading"
                    :disabled="oficialesCumplimiento.isLoading"
                    :transform-item="mapearInstitucion"
                    class="flex-grow-1 flex-shrink-1"
                    label="Institución"
                    placeholder="Seleccione una institución"
                    itemText="nombre_completo"
                    itemValue="id"
                    hide-details
                    @search="buscarInstitucion"
                    @load-more="cargarMasInstituciones"
                    clearable
                />
            </v-col>

            <v-col cols="12" class="d-flex align-center my-4">
                <div class="d-flex align-center flex-wrap" style="gap: 16px;">
                    <v-switch
                        v-model="filtros.solo_usuarios_suspendidos"
                        :disabled="oficialesCumplimiento.isLoading"
                        :truthy-value="true"
                        :false-value="false"
                        hide-details
                        label="Solo usuarios suspendidos"
                        color="secondary"
                        x-large
                        class="d-inline-block mt-0"
                    />
                    <v-switch
                        v-model="filtros.solo_oficiales_verificados"
                        :disabled="oficialesCumplimiento.isLoading"
                        :truthy-value="true"
                        :false-value="false"
                        hide-details
                        label="Solo oficiales verificados"
                        color="secondary"
                        x-large
                        class="d-inline-block mt-0"
                    />
                </div>
            </v-col>

            <v-col cols="12" sm="6">
                <v-btn 
                    color="secondary" 
                    x-large
                    style="width: 100%"
                    :disabled="oficialesCumplimiento.isLoading"
                    @click.stop="limpiarFiltros"
                >
                    Limpiar
                </v-btn>
            </v-col>

            <v-col cols="12" sm="6">
                <v-btn 
                    color="primary" 
                    x-large
                    style="width: 100%"
                    :loading="oficialesCumplimiento.isLoading"
                    @click.stop="buscarOficiales"
                >
                    Buscar
                </v-btn>
            </v-col>
        </v-row>

        <!-- Tabla -->
        <DataTableComponent 
            :items="oficialesCumplimiento.data"
            :show_loading="oficialesCumplimiento.isLoading"
            :headers="headers"
            :total_registros="totalOficialesCumplimiento"
            class="mt-4"
            v-models:pagina="paginacion.page"
            v-models:select="paginacion.per_page"
            @paginar="manejarpaginacion"
        >
            <template v-slot:item.institucion="{ item }">
                {{ item.institucion.codigo }} - 
                {{ item.institucion.nombre }}
            </template>
            <template v-slot:item.estado="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 16px">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="item.verificacion?.verificado" v-on="on" v-bind="attrs" color="success">mdi-check-decagram</v-icon>
                            <v-icon v-else v-on="on" v-bind="attrs" color="orange accent-2">mdi-alert-decagram</v-icon>
                        </template>
                        <span>{{ item.verificacion?.verificado ? 'Verificado' : 'Pendiente de verificación' }}</span>
                    </v-tooltip>

                    <v-tooltip v-if="item.nuevos_documentos_subidos > 0" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" color="info">mdi-file-document-alert</v-icon>
                        </template>
                        <span>Hay cambios ({{ item.nuevos_documentos_subidos }}) en los documentos de SGAS y revisión de procesos de compra</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex align-center justify-center" style="gap: 8px">
                    <!-- manejo de usuarios -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on" v-bind="attrs" @click.stop="confirmarCambioEstadoOficial(item)" icon>
                                <v-icon v-if="item.usuario.es_oficial_de_cumplimiento_suspendido" color="secondary">mdi-account-lock-open-outline</v-icon>
                                <v-icon v-else color="secondary">mdi-account-lock-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{`${item.usuario.es_oficial_de_cumplimiento_suspendido ? 'Habilitar usuario' : 'Suspender usuario'}`}}</span>
                    </v-tooltip>

                    <!-- justificacion suspension -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on" 
                                v-bind="attrs"
                                :disabled="!item.suspension?.adjunto"
                                @click.stop="visualizarJustificacionSuspension(item)"
                                icon 
                            >
                                <v-icon color="secondary">mdi-file-eye-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver motivo de {{ item.suspension?.id_tipo_solicitud === 1 ? 'suspensión' : 'habilitación' }}</span>
                    </v-tooltip>

                    <!-- redirecciones -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on" 
                                v-bind="attrs" 
                                :to="`/documentos-oficial-cumplimiento/${item.usuario.id}`"
                                icon 
                            >
                                <v-icon color="secondary">mdi-arrow-right-bold</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver documentos subidos</span>
                    </v-tooltip>
                </div>
            </template>
        </DataTableComponent>
        <ConfirmationModalComponent 
            :is-open="confirmacionCambioEstadoOficial"
            :is-loading="cambioEstadoOficial.isLoading"
            :description="`¿Desea ${ oficialActivo?.suspension?.aprobado_por ? 'habilitar el' : 'suspender al' } oficial de cumplimiento?`"
            @cancel="confirmacionCambioEstadoOficial = false"
            @confirm="cambiarEstadoOficialCumplimiento"
        />
        <ModalSuspenderOficial 
            :is-open="modalSuspensionOdcAbierto"
            :adjunto-requerido="!oficialActivo?.usuario.es_oficial_de_cumplimiento_suspendido"
            @on-visibility-change="cerrarModalSuspensionOdc"
            @on-save="confirmarSuspensionOdc"
        />
        <PdfModal 
            :is-open="modalVerJustificacionSuspensionAbierta"
            :filename="`justificacion-suspension(${oficialActivo?.nombre_completo})`"
            :source-loadable="visualizacionJustificacion"
            @on-visibility-change="cerrarVisualizacionJustificacionSuspension"
        />
    </Fragment>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import ProgresiveLoadAutocomplete from '@/components/utils/ProgresiveLoadAutocomplete.vue';
import InstitucionesSinOficial from './InstitucionesSinOficial.vue';
import {
    createPageable, 
    togglePageable, 
    setPageableResponse,
    isResponseSuccesful,
    createLoadable,
    toggleLoadable,
    setLoadableResponse,
} from '@/utils/loadable';
import { Fragment } from 'vue-fragment';
import moment from 'moment';
import ModalSuspenderOficial from './ModalSuspenderOficial.vue';
import { convertToFormData } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';

export default {
    name: 'ListaOficialesCumplimiento',
    components: { DataTableComponent, Fragment, ProgresiveLoadAutocomplete, ConfirmationModalComponent, InstitucionesSinOficial, ModalSuspenderOficial, PdfModal },
    data: () => ({
        // UI
        oficialActivo: null,
        headers: [
            { align: 'center', sortable: false, text: 'Nombre', value: 'nombre_completo' },
            { align: 'center', sortable: false, text: 'Perfil', value: 'perfil.nombre' },
            { align: 'center', sortable: false, text: 'Institución', value: 'institucion' },
            { align: 'center', sortable: false, text: 'Estado', value: 'estado' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
        confirmacionCambioEstadoOficial: false,
        // Lista de oficiales
        oficialesCumplimiento: createPageable([], 10),
        filtros: {
            nombre: null,
            id_institucion: null,
            solo_usuarios_suspendidos: false,
            solo_oficiales_verificados: false,
        },
        paginacion: {
            page: 1,
            per_page: 10,
        },
        // Instituciones
        instituciones: createPageable([], 30),
        filtroInstitucion: '',
        paginacionInstituciones: {
            page: 1,
            per_page: 30,
        },
        // Cambio de estado de suspension
        cambioEstadoOficial: createLoadable(null),
        modalSuspensionOdcAbierto: false,
        justificacionSuspension: null,
        modalVerJustificacionSuspensionAbierta: false,
        visualizacionJustificacion: createLoadable(null),
        // Reporte
        generacionReporte: createLoadable(null),
    }),
    computed: {
        totalOficialesCumplimiento() {
            return this.oficialesCumplimiento.pagination.total_rows;
        },
    },
    methods: {
        // UI
        manejarpaginacion(paginacionActualizada) {
            const { pagina, cantidad_por_pagina } = paginacionActualizada;
            this.paginacion = { page: pagina, per_page: cantidad_por_pagina };
        },
        buscarOficiales() {
            this.paginacion = { ...this.paginacion, page: 1 };
        },
        limpiarFiltros() {
            // Limpiar filtro institucion 
            this.filtroInstitucion = '';
            this.paginacionInstituciones = { ...this.paginacionInstituciones, page: 1 };
            this.instituciones = createPageable([], 30);
            this.cargarInstituciones();

            // Limpiar demas filtros
            this.filtros = {
                id_institucion: null,
                nombre: null,
                solo_usuarios_suspendidos: false,
                solo_oficiales_verificados: false,
            };
            this.paginacion = { ...this.paginacion, page: 1 };
        },
        confirmarCambioEstadoOficial(oficial) {
            if (oficial.suspension && !oficial.suspension.aprobado_por && !oficial.suspension.rechazado_por) {
                this.pushAppMessage({ type: 'info', message: 'El oficial de cumplimiento ya tiene una solicitud pendiente de revisión.' });
                return;
            }

            this.oficialActivo = oficial;
            this.modalSuspensionOdcAbierto = true;
        },
        confirmarSuspensionOdc(justificacion) {
            this.justificacionSuspension = justificacion;
            this.confirmacionCambioEstadoOficial = true;
        },
        // instituciones
        mapearInstitucion(institucion) {
            return ({ ...institucion, nombre_completo: `${institucion.codigo} - ${institucion.nombre}` });
        },
        async buscarInstitucion(termino) {
            if (this.filtroInstitucion === termino) return;
            
            this.filtroInstitucion = termino;
            this.instituciones.data = [];
            this.paginacionInstituciones.page = 1;
            this.cargarInstituciones();
        },
        async cargarMasInstituciones() {
            if (this.instituciones.isLoading) return;

            const { page, per_page, total_rows } = this.instituciones.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionInstituciones.page = page + 1;
            this.paginacionInstituciones.per_page = per_page;
            this.cargarInstituciones();
        },
        cerrarModalSuspensionOdc(visible) {
            this.modalSuspensionOdcAbierto = visible;
        },
        cerrarVisualizacionJustificacionSuspension(visible) {
            this.modalVerJustificacionSuspensionAbierta = visible;
        },
        // Data
        async cargarOficialesCumplimiento() {
            const filtros = {
                pagination: true,
                ...this.paginacion,
                ...this.filtros,
                solo_usuarios_suspendidos: this.filtros.solo_usuarios_suspendidos ? true : null,
                solo_oficiales_verificados: this.filtros.solo_oficiales_verificados ? true : null,
            }
            togglePageable(this.oficialesCumplimiento);
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarOficialesCumplimiento(filtros);
            setPageableResponse(this.oficialesCumplimiento, data, headers);
        },
        async cargarInstituciones() {
            const filtros = {
                pagination: true,
                per_page: this.paginacionInstituciones.per_page,
                page: this.paginacionInstituciones.page,
                busqueda: this.filtroInstitucion,
                excluir_centros_escolares: true,
            };

            togglePageable(this.instituciones);
            const copiaInstituciones = this.instituciones.data;
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarInstituciones(filtros);
            setPageableResponse(this.instituciones, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { page } = this.paginacionInstituciones;
                this.paginacionInstituciones.page = page > 1 ? page - 1 : 1;
                return;
            }

            this.instituciones.data = copiaInstituciones.concat(data.data);
        },
        async cambiarEstadoOficialCumplimiento() {
            if (!this.oficialActivo) return;

            const formData = convertToFormData(this.justificacionSuspension);
            toggleLoadable(this.cambioEstadoOficial);
            const action = this.oficialActivo.usuario.es_oficial_de_cumplimiento_suspendido
                ? () => this.services.VerificacionDocumentosOdc.habilitarOficialCumplimiento(this.oficialActivo.usuario.id,)
                : () => this.services.VerificacionDocumentosOdc.suspenderOficialCumplimiento(this.oficialActivo.usuario.id, formData);

            const { data } = await action(this.oficialActivo.usuario.id);
            this.confirmacionCambioEstadoOficial = false;
            setLoadableResponse(this.cambioEstadoOficial, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarOficialesCumplimiento();
        },
        async generarReporte() {
            toggleLoadable(this.generacionReporte);
            const { data } = await this.services.VerificacionDocumentosOdc.generarReporteOficialesCumplimiento();
            setLoadableResponse(this.generacionReporte, data, { skipOnSuccess: true, isFile: true });

            if (data && !('error' in data)) {
                const file = new File([data], `reporte_oficiales_cumplimiento${moment().format('DDMMYYYYHHmm')}.xlsx`,
                    { type: "application/octet-stream" },
                );

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = file.name;
                link.click();            
            }
        },
        async visualizarJustificacionSuspension(oficial) {
            this.oficialActivo = oficial;
            this.modalVerJustificacionSuspensionAbierta = true;
            toggleLoadable(this.visualizacionJustificacion);
            const { data } = await this.services.VerificacionDocumentosOdc.visualizarJustificacionSuspension(oficial.usuario.id);
            setLoadableResponse(this.visualizacionJustificacion, data, { isFile: true });
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarOficialesCumplimiento();
            },
            deep: true,
            immediate: true,
        }
    },
    created() {
        this.cargarInstituciones();
    },
}
</script>
<style scoped>
@media (max-width: 600px) {
    :deep(.filtros-header button) {
        width: 100%;
    }
}
</style>