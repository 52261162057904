<template>
    <v-dialog v-model="internalOpen" max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 0; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">
                <v-form ref="form">
                    <v-alert v-if="form.tipo_gestion?.solo_envio_de_correo" type="info" class="mb-4" dense>
                        La comunicación de este tipo de gestión se realiza únicamente vía correo
                        electrónico.
                        <br /> <br />
                        También puedes hacer uso de los canales oficiales de denuncia, llamando al
                        <b>135</b> o enviando un correo a <b>denuncias@dinac.gob.sv</b>
                    </v-alert>
                    <v-select
                        v-model="form.tipo_gestion"
                        :items="tiposDeGestion.data"
                        :loading="tiposDeGestion.isLoading"
                        label="Tipo de gestión"
                        outlined
                        item-text="nombre"
                        item-value="id"
                        :error-messages="tipo_gestionErrors"
                        @change="$v.form.tipo_gestion.$touch()"
                        @blur="$v.form.tipo_gestion.$touch()"
                        return-object
                    >
                        <template v-slot:selection="{ item, on, attrs }">
                            <v-list-item class="px-0" v-bind="attrs" v-on="on" style="width: 100%;">
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ item.nombre }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on" style="width: 100%;">
                                <v-list-item-content>
                                    <v-list-item-subtitle style="text-wrap: wrap;">
                                        {{ item.nombre }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                    <v-text-field
                        v-model="form.titulo"
                        label="Titulo"
                        placeholder="Titulo"
                        clearable
                        outlined
                        :error-messages="tituloErrors"
                        @change="$v.form.titulo.$touch()"
                        @blur="$v.form.titulo.$touch()"
                    />
                    <v-textarea
                        v-model="form.descripcion"
                        label="Descripción"
                        placeholder="Descripción"
                        outlined
                        clearable
                        no-resize
                        rows="3"
                        :error-messages="descripcionErrors"
                        @change="$v.form.descripcion.$touch()"
                        @blur="$v.form.descripcion.$touch()"
                    />
                    <v-text-field
                        v-model="form.telefono"
                        label="Teléfono"
                        placeholder="Teléfono"
                        clearable
                        outlined
                        :error-messages="telefonoErrors"
                        @change="$v.form.telefono.$touch()"
                        @blur="$v.form.telefono.$touch()"
                        v-mask="'####-####'"
                    />
                    <v-file-input
                        v-model="form.adjunto"
                        label="Adjunto"
                        placeholder="Seleccione un PDF"
                        outlined
                        light
                        accept="application/pdf"
                    />

                    <div 
                        class="d-flex pb-4 flex-wrap flex-sm-nowrap justify-center align-center" 
                        style="gap: 16px; position: sticky; bottom: 0px; background-color: #fff !important;"
                    >
                        <v-btn 
                            @click.stop="cerrarModal" 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary"
                            style="width: 100%"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            @click.stop="crearGestion"
                            class="flex-grow-1 flex-shrink-1" 
                            color="primary"
                            style="width: 100%"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { required, maxLength } from 'vuelidate/lib/validators';
import { tel } from '@/utils/custom-validation-rules.js';
import { Validator } from '@/utils/form-validation.js';

export default {
    name: 'CrearGestion',
    emits: ['on-visibility-change', 'on-save'],
    validations () {
        return {
            form: {
                titulo: { required, maxLength: maxLength(250), },
                descripcion: { required, maxLength: maxLength(500) },
                telefono: { tel },
                tipo_gestion: { required },
            }, 
        };
    },
    props: {
        isOpen: { type: Boolean },
        tiposDeGestion: { type: Object },
    },
    data: () => ({
        internalOpen: false,
        form: {
            titulo: null,
            descripcion: null,
            telefono: null,
            tipo_gestion: null,
            adjunto: null,
        },
    }),
    computed: {
        // Errores formulario
        tituloErrors() {
            return new Validator(this.$v.form.titulo).detect().getResult();
        },
        descripcionErrors() {
            return new Validator(this.$v.form.descripcion).detect().getResult();
        },
        telefonoErrors() {
            return new Validator(this.$v.form.telefono).detect().getResult();
        }, 
        tipo_gestionErrors() {
            return new Validator(this.$v.form.tipo_gestion).detect().getResult();
        },
    },
    methods: {
        cerrarModal() {
            this.limpiarFormulario();
            this.internalOpen = false;
        },
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
        },
        crearGestion() {
            this.$v.$touch();

            if (this.$v.$invalid) return;
            this.$emit('on-save', { ...this.form, tipo_gestion: this.form.tipo_gestion.id });
            this.limpiarFormulario();
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>