<template>
    <Fragment>
        <v-btn 
            v-if="!tieneRolAdministrador"
            @click.stop="modalCreacionGestionAbierta = true" 
            :loading="creacionGestion.isLoading"
            class="d-block mb-4" 
            color="primary"
        >
            Crear gestión
        </v-btn>
        <ListaDeGestiones
            :gestiones="gestiones"
            @on-paginar="manejarPaginacionGestiones"
            @on-actualizacion="cargarGestiones"
        />
        <CrearGestion 
            :is-open="modalCreacionGestionAbierta" 
            :tipos-de-gestion="tiposDeGestion"
            @on-visibility-change="manejarCambioVisibilidadCreacionGestion"
            @on-save="confirmarCreacionGestion"
        />
        <ConfirmationModalComponent 
            :is-open="confirmarCreacionGestionAbierta"
            description="¿Desea continuar con la creación de la gestión?"
            :is-loading="creacionGestion.isLoading"
            @confirm="manejarCreacionGestion"
            @cancel="confirmarCreacionGestionAbierta = false"
        />
    </Fragment>
</template>
<script>
import ListaDeGestiones from './gestiones/ListaDeGestiones.vue';
import CrearGestion from './gestiones/CrearGestion.vue';
import { 
    createLoadable, 
    createPageable, 
    isResponseSuccesful, 
    setLoadableResponse, 
    setPageableResponse, 
    shouldFetch, 
    toggleLoadable, 
    togglePageable,
} from '@/utils/loadable';
import { ConfirmationModalComponent } from '@/components/utils';
import { convertToFormData } from '@/utils/data';
import { Fragment } from 'vue-fragment';

export default {
    name: 'Gestiones',
    components: { Fragment, ListaDeGestiones, ConfirmationModalComponent, CrearGestion },
    data: () => ({
        // Creacion gestion
        creacionGestion: createLoadable(null),
        modalCreacionGestionAbierta: false,
        confirmarCreacionGestionAbierta: false,
        creacionGestionInfo: null,
        // Paginacion
        paginacionGestiones: {
            per_page: 10,
            page: 1,
        },
        // Gestiones
        tiposDeGestion: createLoadable([]),
        gestiones: createPageable([], 10),
    }),
    computed: {
        tieneRolAdministrador() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
    },
    methods: {
        // Data
        async cargarTiposDeGestion() {
            toggleLoadable(this.tiposDeGestion);
            const { data } = await this.services.canalesDeComunicacion.cargarTiposDeGestion();
            setLoadableResponse(this.tiposDeGestion, data);
        },
        async cargarGestiones() {
            const filtros = {
                pagination: true,
                ...this.paginacionGestiones,
            }

            togglePageable(this.gestiones);
            const { data, headers } = await this.services.canalesDeComunicacion.cargarGestiones(filtros);
            setPageableResponse(this.gestiones, data, headers);
        },
        // UI
        manejarCambioVisibilidadCreacionGestion(visible) {
            this.modalCreacionGestionAbierta = visible;
        },
        confirmarCreacionGestion(data) {
            this.creacionGestionInfo = data;
            this.modalCreacionGestionAbierta = false;
            this.confirmarCreacionGestionAbierta = true;
        },
        async manejarCreacionGestion() {
            toggleLoadable(this.creacionGestion);

            const formData = convertToFormData(this.creacionGestionInfo);
            const { data } = await this.services.canalesDeComunicacion.crearGestion(formData);
            this.confirmarCreacionGestionAbierta = false;
            setLoadableResponse(this.creacionGestion, data, { showAlertOnSuccess: false, skipOnSuccess: true });

            if (!isResponseSuccesful(data)) return;
            this.pushAppMessage({ type: 'success', message: 'Su consulta se ha enviado correctamente. Se le brindará respuesta vía correo electrónico.' });

            this.cargarGestiones();
        },
        manejarPaginacionGestiones(paginacion) {
            this.paginacionGestiones = paginacion;
        },
    },
    watch: {
        paginacionGestiones: {
            handler() {
                this.cargarGestiones();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        if (shouldFetch(this.tiposDeGestion)) this.cargarTiposDeGestion();  
    },
}
</script>