<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex" style="width: 100%">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>
            <v-card-text class="px-4 pt-4 pb-0">
                <div class="px-1 py-1">
                    <InformacionGeneralComponent />
                    <FormMainCuentaComponent class="mt-4" :creador="solicitante" :estadoSolicitud="estadoSolicitud" readonly />
                </div>
                <div 
                    class="d-flex justify-center align-center py-4" 
                    style="position: sticky; bottom: 0; background: white; gap: 16px;"
                >
                    <v-btn 
                        class="flex-grow-1 flex-shrink-1" 
                        color="primary" 
                        large
                        @click.stop="cerrarModal"
                    >
                        Cerrar
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import FormMainCuentaComponent from '@/views/GestionUsuarios/components/FormMainCuentaComponent.vue';
import InformacionGeneralComponent from '@/views/GestionUsuarios/components/InformacionGeneralComponent.vue';
import { mapMutations, mapState } from 'vuex';
import { Fragment } from 'vue-fragment';
import moment from 'moment';

export default {
    name: 'ModalVerDetalleSolicitud',
    components: { Fragment, InformacionGeneralComponent, FormMainCuentaComponent },
    props: {
        isOpen: { type: Boolean },
        idSolicitudUsuario: { type: [String, Number] },
    },
    data: () => ({
        solicitante: null,
        cargaDeSolicitud: createLoadable(null),
    }),
    computed: {
        ...mapState("solicitudCuenta", ["estadoSolicitud"]),
    },
    methods: {
        ...mapMutations("solicitudCuenta", ["setFormInfoGeneral", "setFormMainCuenta", "setEstadoSolicitud", "resetState"]),
        // UI
        cerrarModal() {
            this.$emit('update:is-open', false);
        },
        // DATA
        async cargarSolicitudUsuario() {
            toggleLoadable(this.cargaDeSolicitud);
            const response = await this.services.VerificacionDocumentosOdc.cargarSolicitudCreacionUsuario(this.idSolicitudUsuario);
            setLoadableResponse(this.cargaDeSolicitud, response.data, { skipOnSuccess: true });

            if (isResponseSuccesful(response.data)) {
                const { data } = response.data;

                this.solicitante = data?.solicitudUsuario?.id_solicitante;
                this.setEstadoSolicitud(data?.solicitudUsuario?.id_estado_solicitud);
                this.setFormInfoGeneral({
                    apellidoCasada: data?.solicitudUsuario?.apellido_casada,
                    nacionalidad: data?.solicitudUsuario?.nacionalidad,
                    tipoSolicitud: data?.solicitudUsuario?.detalle_tipo_solicitud,
                    numeroDocumento: data?.solicitudUsuario?.documento,
                    primerApellido: data?.solicitudUsuario?.primer_apellido,
                    primerNombre: data?.solicitudUsuario?.primer_nombre,
                    segundoApellido: data?.solicitudUsuario?.segundo_apellido,
                    segundoNombre: data?.solicitudUsuario?.segundo_nombre,
                    estadoUsuario: !data?.solicitudUsuario?.estado_usuario,
                });
                this.setFormMainCuenta({
                    cargoUsuario: data?.solicitudUsuario?.cargo_funcional,
                    stckUnidades: data?.solicitudUsuario?.unidades,
                    divisionUsuario: data?.solicitudUsuario?.unidades.length == 1 ? data?.solicitudUsuario?.unidades[0] : null,
                    emailUsuario: data?.solicitudUsuario?.correo_electronico,
                    fechaCaducidadUsuario: moment(data?.solicitudUsuario?.fecha_caducidad).format("DD/MM/YYYY"),
                    infoAdicionalUsuario: data?.solicitudUsuario?.informacion_adicional,
                    profesionUsuario: data?.solicitudUsuario?.profesion,
                    rolPerfilUsuario: data?.solicitudUsuario?.rol_perfil,
                    telefonoUsuario: data?.solicitudUsuario?.telefono,
                    urlDocumentoInstitucional: data?.solicitudUsuario?.url_documento_institucional,
                    detalleModificacionUsuario: data?.solicitudUsuario?.observaciones_modificacion,
                });
            }
        },
    },
    watch: {
        idSolicitudUsuario: {
            handler(value) {
                if (value) {
                    this.cargarSolicitudUsuario();
                } else {
                    this.resetState();
                }
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.resetState();
    },
}
</script>