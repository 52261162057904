<template>
    <Fragment>
        <v-dialog v-model="modalOpen" max-width="1000">
            <div style="background-color: #FFFFFF; padding: 0 1em 0; position: relative;">
                <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="px-4 py-4"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <div class="px-1 mt-1">
                    <ProgresiveLoadAutocomplete
                        v-model="idInstitucion"
                        :pageable="instituciones"
                        :loading="instituciones.isLoading"
                        class="flex-grow-1 flex-shrink-1"
                        label="Institución"
                        placeholder="Seleccione una institución"
                        itemText="nombre"
                        itemValue="id"
                        hide-details
                        @search="buscarInstitucion"
                        @load-more="cargarMasInstituciones"
                        clearable
                    />
                    <DataTableComponent 
                        dense
                        no-gutters
                        class="mt-4"
                        :headers="headers"
                        :items="institucionesSinOficial.data"
                        :show_loading="institucionesSinOficial.isLoading"
                        :total_registros="total"
                        v-models:pagina="paginacion.page"
                        v-models:select="paginacion.per_page"
                        @paginar="manejarpaginacion"
                    />
                    <div class="text-center py-4" style="position: sticky; bottom: 0; background: #FFF;">
                        <v-btn class="mx-auto" color="primary" @click.stop="cerrarModal">Cerrar</v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>
        <v-btn 
            @click.stop="modalOpen = true"
            class="d-block px-4 py-2"
            style="height: unset !important; min-height: 36px !important; word-break: break-word; white-space: normal; max-width: 100%;"
            color="secondary"
            outlined
        >
            <v-icon class="mr-2" color="secondary">mdi-account-tie-voice-off</v-icon>
            Instituciones sin oficial
        </v-btn>
    </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';
import { createPageable, togglePageable, setPageableResponse, isResponseSuccesful } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ProgresiveLoadAutocomplete } from '@/components/utils';

export default {
    name: 'InstitucionesSinOficial',
    components: { Fragment, DataTableComponent, ProgresiveLoadAutocomplete },
    emits: ['on-visibility-change', 'on-save'],
    data: () => ({
        headers: [
            { align: 'center', sortable: false, text: 'Nombre', value: 'nombre' },
        ],
        modalOpen: false,
        institucionesSinOficial: createPageable([], 10),
        paginacion: {
            page: 1,
            per_page: 10,
        },
        // Paginaciones
        idInstitucion: null,
        instituciones: createPageable([], 30),
        filtroInstitucion: '',
        paginacionInstituciones: {
            page: 1,
            per_page: 30,
        },
    }),
    computed: {
        total() {
            return this.institucionesSinOficial.pagination.total_rows;
        },
    },
    methods: {
        // UI
        manejarpaginacion(paginacionActualizada) {
            const { pagina, cantidad_por_pagina } = paginacionActualizada;
            this.paginacion = { page: pagina, per_page: cantidad_por_pagina };
        },
        cerrarModal() {
            this.modalOpen = false;
        },
        // Data
        async cargarInstitucionesSinOficial() {
            const filtros = {
                pagination: true,
                ...this.paginacion,
                id_institucion: this.idInstitucion,
            };
            togglePageable(this.institucionesSinOficial);
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarInstitucionesSinOficial(filtros);
            setPageableResponse(this.institucionesSinOficial, data, headers);
        },
        // filtro instituciones
        async buscarInstitucion(termino) {
            if (this.filtroInstitucion === termino) return;
            
            this.filtroInstitucion = termino;
            this.instituciones.data = [];
            this.paginacionInstituciones.page = 1;
            this.cargarInstituciones();
        },
        async cargarMasInstituciones() {
            if (this.instituciones.isLoading) return;

            const { page, per_page, total_rows } = this.instituciones.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionInstituciones.page = page + 1;
            this.paginacionInstituciones.per_page = per_page;
            this.cargarInstituciones();
        },
        async cargarInstituciones() {
            const filtros = {
                pagination: true,
                per_page: this.paginacionInstituciones.per_page,
                page: this.paginacionInstituciones.page,
                busqueda: this.filtroInstitucion,
                excluir_centros_escolares: true,
            };

            togglePageable(this.instituciones);
            const copiaInstituciones = this.instituciones.data;
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarInstituciones(filtros);
            setPageableResponse(this.instituciones, data, headers, { skipOnSuccess: true, persistDataOnError: true });

            if (!isResponseSuccesful(data)) {
                // Hacemos rollback de la pagina en caso de error para volver a cargar la misma al scrollear
                const { page } = this.paginacionInstituciones;
                this.paginacionInstituciones.page = page > 1 ? page - 1 : 1;
                return;
            }

            this.instituciones.data = copiaInstituciones.concat(data.data);
        },
    },
    watch: {
        paginacion: {
            handler() {
                this.cargarInstitucionesSinOficial();
            },
            deep: true,
            immediate: true,
        },
        idInstitucion() {
            this.paginacion = { ...this.paginacion, page: 1 };
        },
    },
    created() {
        this.cargarInstituciones();
    },
}
</script>