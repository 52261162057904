<template>
    <v-dialog v-model="isOpen" max-width="800px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex" style="width: 100%">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        :disabled="detalle.isLoading"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>
            <v-card-text class="px-4 py-4">
                <div v-if="detalle.isLoading" class="d-flex justify-center my-4">
                    <v-progress-circular :size="40" indeterminate />
                </div>
                <template v-if="detalle.data && !detalle.isLoading">
                    <v-card class="mx-1 my-1">
                        <v-card-title class="align-center" style="gap: 8px;">
                            <v-icon>mdi-folder-text</v-icon>
                            <span class="text-subtitle-2 text-uppercase font-weight-bold">Detalle de solicitud</span>
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" md="6">
                                    <dl>
                                        <dt class="font-weight-bold">Fecha de creación</dt>
                                        <dd>{{ formatDate(detalle.data.created_at) }}</dd>
    
                                        <dt class="font-weight-bold mt-2">Institución solicitante</dt>
                                        <dd>{{ detalle.data.solicitante.institucion.nombre }}</dd>
    
                                        <dt class="font-weight-bold mt-2">Unidad solicitante</dt>
                                        <dd>{{ detalle.data.solicitante.unidad.nombre }}</dd>

                                        <template v-if="detalle.data.solicitud_usuario?.url_documento_institucional">
                                            <dt class="font-weight-bold mt-2">Documento</dt>
                                            <dd>
                                                <a
                                                    @click="descargarDocumentoInstitucional(detalle.data.solicitud_usuario.url_documento_institucional)"
                                                    class="d-inline-block secondary--text text-uppercase text-caption font-weight-bold mt-1"
                                                    target="_blank"
                                                >
                                                    <v-icon color="secondary">mdi-download</v-icon>
                                                    Descargar documento
                                                </a>
                                            </dd>
                                        </template>
                                    </dl>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <dl>
                                        <dt class="font-weight-bold">Nombre de Solicitante</dt>
                                        <dd>{{ detalle.data.solicitante.nombre_completo }}</dd>
    
                                        <dt class="font-weight-bold mt-2">Correo de solicitante</dt>
                                        <dd>{{ detalle.data.solicitante.email }}</dd>
    
                                        <dt class="font-weight-bold mt-2">Perfil de solicitante</dt>
                                        <dd>{{ detalle.data.solicitante.perfiles.map((perfil) => perfil.nombre).join(' ') }}</dd>
                                    </dl>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mx-1 mb-1 mt-4">
                        <v-card-title class="align-center" style="gap: 8px;">
                            <v-icon>mdi-account</v-icon>
                            <span class="text-subtitle-2 text-uppercase font-weight-bold">Solicitado para</span>
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" md="6">
                                    <dl>
                                        <dt class="font-weight-bold">Nombre</dt>
                                        <dd>{{ detalle.data.oficial.nombre_completo }}</dd>
                                    </dl>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <dl>
                                        <dt class="font-weight-bold">Correo</dt>
                                        <dd>{{ detalle.data.oficial.email }}</dd>
                                    </dl>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mx-1 mb-1 mt-4">
                        <v-card-title class="align-center" style="gap: 8px;">
                            <v-icon>mdi-account-switch</v-icon>
                            <span class="text-subtitle-2 text-uppercase font-weight-bold">Cambio de perfil</span>
                        </v-card-title>
                        <v-divider />
                        <v-card-text>
                            <div class="d-flex flex-column flex-md-row justify-center align-center" style="gap: 16px;">
                                <div class="d-flex">
                                    <v-avatar color="#9e9e9e" :size="28">
                                        <v-icon color="white" small>mdi-account-minus</v-icon>
                                    </v-avatar>
                                    <p class="mb-0 ml-2">
                                        <span class="d-block text-subtitle-2 text-uppercase font-weight-bold grayMinsal--text">
                                            {{ detalle.data.oficial.perfiles.map((perfil) => perfil.nombre).join(' ') }}
                                        </span>
                                        <span class="text-caption grayMinsal--text">Perfil actual</span>
                                    </p>
                                </div>
                                <v-divider class="d-none d-md-block" />
                                <v-divider class="d-block d-md-none mx-auto py-8" vertical />
                                <div class="d-flex">
                                    <v-avatar color="primary" :size="28">
                                        <v-icon color="white" small>mdi-account-plus</v-icon>
                                    </v-avatar>
                                    <p class="mb-0 ml-2">
                                        <span class="d-block text-subtitle-2 text-uppercase font-weight-bold">
                                            {{ detalle.data.nuevo_perfil.nombre }}
                                        </span>
                                        <span class="text-caption">Perfil nuevo</span>
                                    </p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </template>
                <div 
                    class="d-flex justify-center align-center pt-4" 
                    style="position: sticky; bottom: 16px; background: white; gap: 16px;"
                >
                    <v-btn 
                        class="flex-grow-1 flex-shrink-1" 
                        color="primary" 
                        large
                        @click.stop="cerrarModal"
                        :disabled="detalle.isLoading"
                    >
                        Cerrar
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'ModalDetalleCambioPerfil',
    props: {
        isOpen: { type: Boolean },
        idSolicitud: { type: [String, Number] },
    },
    data: () => ({
        detalle: createLoadable(null),
    }),
    methods: {
        formatDate,
        cerrarModal() {
            this.$emit('update:is-open', false);
        },
        async cargarDetalleSolicitud() {
            toggleLoadable(this.detalle);
            const { data } = await this.services.VerificacionDocumentosOdc.cargarSolicitudCambioPerfil(this.idSolicitud);
            setLoadableResponse(this.detalle, data);
        },
        async descargarDocumentoInstitucional(ruta) {
            try {
                const response = await this.services.Usuarios.getUrlDocumento({ ruta });
                if (response) {

                    const blob = new Blob([response.data], { type: "application/pdf" });

                    const file = new File([blob], "documento.pdf", { type: "application/pdf" });

                    // Descargar el archivo
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(file);
                    link.download = 'documento-nombramiento.pdf';
                    link.click();

                    this.document = file;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        isOpen(value) {
            if (value) this.cargarDetalleSolicitud();
        },
    },
}
</script>