<template>
    <v-container>
        <RetryDataLoading :skip="esAdministradorDINAC" :loadable="informacion" :retry-handler="reintentarCargaOdc">
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
                <v-tab key="verificacion" tab-value="verificacion">
                    <template v-if="esAdministradorDINAC">
                        <v-icon class="mr-2">mdi-account-check-outline</v-icon>
                        Oficiales de cumplimiento
                    </template>
                    <template v-else>
                        <v-icon class="mr-2">mdi-check-outline</v-icon>
                        Verificación
                    </template>
                </v-tab>
                <v-tab v-if="esAdministradorDINAC" key="solicitudes_suspension" tab-value="solicitudes_suspension">
                        <v-icon class="mr-2">mdi-badge-account-outline</v-icon>
                        Solicitudes de usuario
                </v-tab>
                <v-tab key="documentos" tab-value="documentos">
                    <v-icon class="mr-2">mdi-file-document-outline</v-icon>
                    Documentos
                </v-tab>
    
                <v-tab key="gestiones" tab-value="gestiones">
                    <v-icon class="mr-2">mdi-email-open-outline</v-icon>
                    Gestiones
                </v-tab>
                
                <v-tabs-items v-model="tab">
                    <v-tab-item key="verificacion" value="verificacion">
                        <div class="px-md-4 py-4">
                            <VerificacionOficialCumplimiento v-if="tab === 'verificacion'" />
                        </div>
                    </v-tab-item>
    
                    <v-tab-item v-if="esAdministradorDINAC" key="solicitudes_suspension" value="solicitudes_suspension">
                        <div class="px-md-4 py-4">
                            <SolicitudesSuspension v-if="tab === 'solicitudes_suspension'" />
                        </div>
                    </v-tab-item>
    
                    <v-tab-item key="documentos" value="documentos">
                        <div class="px-md-4 py-4">
                            <PublicacionDocumentos v-if="tab === 'documentos'" />
                        </div>
                    </v-tab-item>
    
                    <v-tab-item key="gestiones" value="gestiones">
                        <div class="px-md-4 py-4">
                            <Gestiones v-if="tab === 'gestiones'" />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </RetryDataLoading>
    </v-container>
</template>
<script>
import VerificacionOficialCumplimiento from './components/VerificacionOficialCumplimiento.vue';
import PublicacionDocumentos from './components/PublicacionDocumentos.vue';
import SolicitudesSuspension from './components/SolicitudesSuspension.vue';
import Gestiones from './components/Gestiones.vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import { RetryDataLoading } from '@/components/utils';

export default {
    components: { Gestiones, PublicacionDocumentos, VerificacionOficialCumplimiento, SolicitudesSuspension, RetryDataLoading },
    data: () => ({
        tab: null,
    }),
    computed: {
        ...mapState('panelAdministracionOdc', ['informacion']),
        esAdministradorDINAC() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
    },
    methods: {
        ...mapActions('panelAdministracionOdc', ['cargarApartados', 'cargarInformacionOdc']),
        ...mapMutations('panelAdministracionOdc', ['resetearEstadoOficialCumplimiento']),
        reintentarCargaOdc() {
            this.cargarInformacionOdc();
        },
    },
    beforeDestroy() {
        this.resetearEstadoOficialCumplimiento();
    },
}
</script>
<style scoped>
:deep(.v-slide-group__prev),
:deep(.v-slide-group__next) {
    display: none !important;
}

:deep(.v-tabs-bar) {
    position: relative;
    z-index: 6;
}

:deep(.v-tabs-bar::before) {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: rgba(0,0,0,.12);
    bottom: 0;
    left: 0;
    z-index: 5;
}

:deep(.v-slide-group__wrapper) {
    z-index: 6;
}
</style>