<template>
    <v-dialog v-model="internalOpen" max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 2em; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">
                <v-file-input
                    v-model="adjunto"
                    label="Adjunto"
                    placeholder="Seleccione un PDF"
                    outlined
                    light
                    accept="application/pdf"
                />
                <div class="d-flex justify-center align-center" style="gap: 16px;">
                    <v-btn 
                        @click.stop="cerrarModal" 
                        class="flex-grow-1 flex-shrink-1" 
                        color="secondary"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn 
                        :disabled="!adjunto"
                        @click.stop="guardarArchivo"
                        class="flex-grow-1 flex-shrink-1" 
                        color="primary"
                    >
                        Guardar
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { isNil } from 'lodash';

export default {
    name: 'AdjuntoConvocatoria',
    emits: ['on-visibility-change', 'on-save'],
    props: {
        isOpen: { type: Boolean },
    },
    data: () => ({
        internalOpen: false,
        adjunto: null,
    }),
    methods: {
        cerrarModal() {
            this.internalOpen = false;
        },
        guardarArchivo() {
            this.$emit('on-save', this.adjunto);
            this.adjunto = null;
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>