<template>
    <Fragment>
        <v-dialog v-model="isOpen" max-width="800px" persistent>
            <v-card style="position: relative;">
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <v-form @submit.prevent="manejarEnvioFormulario" ref="form">
                        <v-textarea 
                            v-model="motivo_rechazo" 
                            label="Motivo" 
                            placeholder="Motivo de rechazo" 
                            outlined 
                            no-resize
                            rows="4"
                            light
                            :rules="motivoRechazoRules"
                        />
                        <div class="d-flex justify-center align-center mt-4" style="gap: 16px;">
                            <v-btn 
                                class="flex-grow-1 flex-shrink-1" 
                                color="secondary" 
                                large
                                @click.stop="cerrarModal"
                            >
                                Cerrar
                            </v-btn>

                            <v-btn 
                                class="flex-grow-1 flex-shrink-1" 
                                color="primary" 
                                large
                                type="submit"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment';

export default {
    name: 'RechazarGestionUsuario',
    emits: ['on-save'],
    components: { Fragment },
    props: {
        isOpen: { type: Boolean },
    },
    data: () => ({
        motivo_rechazo: '',
    }),
    computed: {
        motivoRechazoRules() {
            return [v => !!v || 'El campo es requerido'];
        },
    },
    methods: {
        cerrarModal() {
            this.$emit('update:is-open', false);
            this.motivo_rechazo = '';
            if (!this.$refs.form.reset()) return;
        },
        manejarEnvioFormulario() {
            if (!this.$refs.form.validate()) return;

            const cargaUtil = { motivo_rechazo: this.motivo_rechazo };
            this.cerrarModal();
            this.$emit('on-save', cargaUtil);
            this.$refs.form.reset();
            this.motivo_rechazo= '';
        },
    },
}
</script>