<template>
    <ListaOficialesCumplimiento v-if="esAdministradorDINAC" />
    <DocumentosOficialCumplimiento v-else />
</template>
<script>
import ListaOficialesCumplimiento from './verificacion/ListaOficialesCumplimiento.vue';
import DocumentosOficialCumplimiento from './verificacion/DocumentosOficialCumplimiento.vue';

export default {
    name: 'VerificacionOficialCumplimiento',
    components: { ListaOficialesCumplimiento, DocumentosOficialCumplimiento },
    computed: {
        esAdministradorDINAC() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
    }
}
</script>