<template>
<v-dialog v-model="internalOpen" persistent max-width="700">
    <div style="background-color: #FFFFFF; padding: 0 1em 0; position: relative;">
        <div  style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
            <v-btn 
                style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                class="px-4 mt-4"
                @click.stop="cerrarModal" 
                text
                rounded
            >
                <v-icon>mdi-window-close</v-icon>
            </v-btn>
        </div>
        <div class="px-1 mt-4">
            <v-form ref="form">
                <v-file-input
                    v-model="form.adjunto"
                    label="Adjunto"
                    placeholder="Seleccione un PDF"
                    outlined
                    light
                    accept="application/pdf"
                />
                <v-textarea
                    v-model="form.contenido"
                    label="Descripción"
                    placeholder="Descripción"
                    outlined
                    clearable
                    no-resize
                    rows="3"
                    :error-messages="contenidoErrors"
                    @change="$v.form.contenido.$touch()"
                    @blur="$v.form.contenido.$touch()"
                />
                <div 
                    class="d-flex pb-4 flex-wrap flex-sm-nowrap justify-center align-center" 
                    style="gap: 16px; position: sticky; bottom: 0px; background-color: #fff !important;"
                >
                    <v-btn 
                        @click.stop="cerrarModal" 
                        class="flex-grow-1 flex-shrink-1" 
                        color="secondary"
                        style="width: 100%"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn 
                        @click.stop="validarFormulario"
                        class="flex-grow-1 flex-shrink-1" 
                        color="primary"
                        style="width: 100%"
                    >
                        Guardar
                    </v-btn>
                </div>
            </v-form>
        </div>
    </div>
</v-dialog>
</template>
<script>
import { isNil } from 'lodash';
import { required, maxLength } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation.js';

export default {
    name: 'EnviarCorreoElectronico',
    emits: ['on-visibility-change', 'on-save'],
    validations () {
        return {
            form: {
                contenido: { required, maxLength: maxLength(500) },
            }, 
        };
    },
    props: {
        isOpen: { type: Boolean },
    },
    data: () => ({
        internalOpen: false,
        form: {
            contenido: null,
            adjunto: null,
        },
    }),
    computed: {
        contenidoErrors() {
            return new Validator(this.$v.form.contenido).detect().getResult();
        },
    },
    methods: {
        cerrarModal() {
            this.limpiarFormulario();
            this.internalOpen = false;
        },
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
        },
        validarFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.$emit('on-save', { ...this.form });
            this.limpiarFormulario();
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
    },
}
</script>