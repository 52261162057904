<template>
    <div>
        <v-form v-if="puedeManipularDocumentos" ref="form" class="mt-4" @submit.prevent="manejarEnvioFormulario">
            <div class="d-flex flex-wrap flex-md-nowrap" style="gap: 16px">
                <v-text-field 
                    v-model="form.nombre"
                    nombre="Nombre"
                    placeholder="Ingrese el nombre del documento"
                    class="flex-grow-1 flex-shrink-1"
                    style="width: 100%"
                    :error-messages="nombreErrors"
                    :disabled="publicacionDocumento.isLoading"
                    outlined
                    @change="$v.form.nombre.$touch()"
                    @blur="$v.form.nombre.$touch()"
                />
                <v-file-input
                    v-model="form.adjunto"
                    label="Adjunto"
                    placeholder="Seleccione un archivo adjunto"
                    class="flex-grow-1 flex-shrink-1"
                    style="width: 100%"
                    :error-messages="adjuntoErrors"
                    :disabled="publicacionDocumento.isLoading"
                    accept="application/pdf"
                    outlined
                    light
                    @blur="$v.form.adjunto.$touch()"
                />
            </div>
            <div style="transform: translateY(-8px)">
                <v-switch
                    v-model="form.permitir_descarga"
                    :disabled="publicacionDocumento.isLoading"
                    hide-details
                    label="Permitir descarga"
                    color="secondary"
                    x-large
                    class="d-inline-block"
                />
            </div>
            <v-btn 
                color="primary"
                class="mt-4"
                type="submit"
                :loading="publicacionDocumento.isLoading"
                x-large 
            >
                Guardar
            </v-btn>
        </v-form>

        <DataTableComponent 
            :items="documentosPublicados.data"
            :headers="headers"
            :show_loading="documentosPublicados.isLoading"
            :total_registros="totalDocumentosPublicados"
            v-models:pagina="paginacionDocumentosPublicados.page"
            v-models:select="paginacionDocumentosPublicados.per_page"
            @paginar="manejarPaginacionDocumentosPublicados"
            class="mt-4"
        >
            <template v-slot:item.created_at="{ item }">
                <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:item.created_by="{ item }">
                <span>{{ obtenerNombreCompletoPersona(item.publicado_por) }}</span>
            </template>
            <template v-slot:item.acciones="{ item }">
                <div class="d-flex justify-center" style="gap: 8px;">
                    <!--Visualizar adjunto -->
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on"
                                v-bind="attrs"
                                :disabled="visualizacionDocumentoPublicado.isLoading"
                                @click.stop="visualizarDocumentoPublicado(item)"
                                icon
                            >
                                <v-icon color="secondary">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Ver adjunto</span>
                    </v-tooltip>

                    <!-- Eliminar adjunto -->
                    <v-tooltip v-if="puedeManipularDocumentos" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on"
                                v-bind="attrs"
                                :disabled="eliminacionDocumento.isLoading"
                                @click.stop="confirmarEliminacionDocumento(item)"
                                icon
                            >
                                <v-icon color="secondary">mdi-trash-can</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar adjunto</span>
                    </v-tooltip>

                    <!-- Cambiar visibilidad -->
                    <v-tooltip v-if="puedeManipularDocumentos" top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                v-on="on"
                                v-bind="attrs"
                                :disabled="cambioVisibilidad.isLoading"
                                @click.stop="confirmarCambioVisibilidad(item)"
                                icon
                            >
                                <v-icon :color="item.permitir_descarga ? 'green accent-5' : 'grey accent-3'" style="font-size: 46px !important;">
                                    {{ item.permitir_descarga ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.permitir_descarga ? 'Evitar descargar documento' : 'Permitir descargar documento' }}</span>
                    </v-tooltip>
                </div>
            </template>
        </DataTableComponent>

        <ConfirmationModalComponent 
            :is-open="confirmacionPublicacionDocumento"
            :is-loading="publicacionDocumento.isLoading"
            description="¿Desea publicar el documento?"
            @confirm="publicarDocumento"
            @cancel="confirmacionPublicacionDocumento = false"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionEliminacionDocumento"
            :is-loading="eliminacionDocumento.isLoading"
            description="¿Desea eliminar el documento?"
            @confirm="eliminarDocumento"
            @cancel="confirmacionEliminacionDocumento = false"
        />

        <ConfirmationModalComponent 
            :is-open="confirmacionCambioVisibilidad"
            :is-loading="cambioVisibilidad.isLoading"
            :description="`¿Desea ${documentoActivo?.activo ? 'evitar' : 'permitir'} que el documento sea descargado?`"
            @confirm="cambiarEstadoDescargaDocumentoPublicado"
            @cancel="confirmacionCambioVisibilidad = false"
        />

        <PdfModal
            :isOpen="modalVisualizacionAdjuntoAbierta" 
            :source-loadable="visualizacionDocumentoPublicado"
            :allow-download="puedeDescargarDocumento"
            :filename="documentoActivo?.nombre ?? 'documento-publicado'"
            @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
        />
    </div>
</template>
<script>
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import { Validator } from '@/utils/form-validation';
import { required, maxLength } from 'vuelidate/lib/validators'
import { 
    createLoadable, 
    toggleLoadable, 
    setLoadableResponse, 
    createPageable, 
    togglePageable, 
    setPageableResponse, 
    isResponseSuccesful,
} from '@/utils/loadable';
import { formatDate } from '@/utils/datetime';
import { convertToFormData, removePropsFromObjectIfFalsy } from '@/utils/data';
import { obtenerNombreCompletoPersona } from '@/utils/data';
import PdfModal from '@/components/PdfModal.vue';

const dataInicialForm = {
    nombre: null,
    adjunto: null,
    permitir_descarga: true,
};

export default {
    name: 'PublicacionDocumentos',
    components: { DataTableComponent, ConfirmationModalComponent, PdfModal },
    validations: () => {
        return {
            form: {
                nombre: { required, maxLength: maxLength(250) } ,
                adjunto: { required },
            },
        };
    },
    data: () => ({
        // UI
        form: { ...dataInicialForm },
        headers: [
            { align: 'center', sortable: false, text: 'Nombre', value: 'nombre' },
            { align: 'center', sortable: false, text: 'Fecha de publicación', value: 'created_at' },
            { align: 'center', sortable: false, text: 'Publicado por', value: 'created_by' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
        documentoActivo: null,
        // Publicacion documentos
        confirmacionPublicacionDocumento: false,
        publicacionDocumento: createLoadable(null),
        // Listar documentos publicados
        documentosPublicados: createPageable([], 10),
        paginacionDocumentosPublicados: {
            page: 1,
            per_page: 10,
        },
        // Visualizacion documento
        modalVisualizacionAdjuntoAbierta: false,
        visualizacionDocumentoPublicado: createLoadable(null),
        // Eliminar documentos
        confirmacionEliminacionDocumento: false,
        eliminacionDocumento: createLoadable(null),
        // Cambio visibilidad
        cambioVisibilidad: createLoadable(null),
        confirmacionCambioVisibilidad: false,
    }),
    computed: {
        nombreErrors() {
            return new Validator(this.$v.form.nombre).detect().getResult();
        },
        adjuntoErrors() {
            return new Validator(this.$v.form.adjunto).detect().getResult();
        },
        puedeManipularDocumentos() {
            return this.haveRoles(['ROLE_VERIFICACION_NOMBRAMIENTO_ODC_DINAC']);
        },
        puedeDescargarDocumento() {
            return this.puedeManipularDocumentos || !!this.documentoActivo?.permitir_descarga;
        },
        totalDocumentosPublicados() {
            return this.documentosPublicados.pagination.total_rows;
        },
    },
    methods: {
        // UI
        formatDate,
        obtenerNombreCompletoPersona,
        manejarVisibilidadModalVisualizacionAdjunto(visible) { 
            this.modalVisualizacionAdjuntoAbierta = visible;
        },
        manejarPaginacionDocumentosPublicados(paginacion) {
            const { pagina, cantidad_por_pagina } = paginacion;
            this.paginacionDocumentosPublicados = {
                page: pagina,
                per_page: cantidad_por_pagina,
            };
        },
        limpiarFormulario() {
            this.$v.form.$reset();
            this.form = { ...dataInicialForm };
        },
        manejarEnvioFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.confirmacionPublicacionDocumento = true;
        },
        confirmarEliminacionDocumento(documento) {
            this.documentoActivo = documento;
            this.confirmacionEliminacionDocumento = true;
        },
        confirmarCambioVisibilidad(documento) {
            this.documentoActivo = documento;
            this.confirmacionCambioVisibilidad = true;
        },
        // Data
        async publicarDocumento() {
            toggleLoadable(this.publicacionDocumento);
            const dataConvertir = removePropsFromObjectIfFalsy(this.form, ['permitir_descarga']);

            const formData = convertToFormData(dataConvertir);
            const { data } = await this.services.VerificacionDocumentosOdc.publicarDocumento(formData);
            this.confirmacionPublicacionDocumento = false;
            setLoadableResponse(this.publicacionDocumento, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.limpiarFormulario();
                this.cargarDocumentosPublicados();
            } 
        },
        async cargarDocumentosPublicados() {
            const filtros = {
                pagination: true,
                ...this.paginacionDocumentosPublicados,
            };

            togglePageable(this.documentosPublicados);
            const { data, headers } = await this.services.VerificacionDocumentosOdc.cargarDocumentosPublicados(filtros);
            setPageableResponse(this.documentosPublicados, data, headers);

            if (isResponseSuccesful(data)) {
                if (data.data.length === 0 && Number(headers.page) !== 1) {
                    this.paginacionDocumentosPublicados = {
                        ...this.paginacionDocumentosPublicados,
                        page: this.paginacionDocumentosPublicados.page - 1,
                    }
                }
            }
        },
        async visualizarDocumentoPublicado(documento) {
            this.modalVisualizacionAdjuntoAbierta = true;
            this.documentoActivo = documento;
            toggleLoadable(this.visualizacionDocumentoPublicado);
            const { data }  = await this.services.VerificacionDocumentosOdc.visualizarDocumentoPublicado(documento.id);
            setLoadableResponse(this.visualizacionDocumentoPublicado, data, { isFile: true });
        },
        async eliminarDocumento() {
            toggleLoadable(this.eliminacionDocumento);
            const { data } = await this.services.VerificacionDocumentosOdc.eliminarDocumentoPublicado(this.documentoActivo.id);
            this.confirmacionEliminacionDocumento = false;
            setLoadableResponse(this.eliminacionDocumento, data, { skipOnSuccess: true, showAlertOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarDocumentosPublicados();
        },
        async cambiarEstadoDescargaDocumentoPublicado() {
            toggleLoadable(this.cambioVisibilidad);
            const payload = { permitir_descarga: !this.documentoActivo.permitir_descarga };
            const { data } = await this.services.VerificacionDocumentosOdc.cambiarEstadoDescargaDocumentoPublicado(this.documentoActivo.id, payload);
            this.confirmacionCambioVisibilidad = false;
            setLoadableResponse(this.cambioVisibilidad, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarDocumentosPublicados();
        },
    },
    watch: {
        paginacionDocumentosPublicados: {
            handler() {
                this.cargarDocumentosPublicados();
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>